// @flow strict
import React from 'react';
import { getContactHref } from '../../../utils';
import styles from './Author.module.scss';
import Meta from '../Meta';
import { useSiteMetadata } from '../../../hooks';

const Author = ({ date }: Props) => {
  const { author } = useSiteMetadata();
  type Props = {
    date: date
  };
  
  return (
    <div className={styles['author']}>
      <img src={'/media/about-2.jpg'}
            className={styles['author__photo']}
            width="48"
            height="48"
            alt={author.name}
            className={styles['author__photo']}/>
      
      <p className={styles['author__title']}>
        Written by
        <a
          href={getContactHref('twitter', author.contacts.twitter)}
          rel="noopener noreferrer"
          target="_blank"
        >
          <p className={styles['author__title-twitter']}>{author.name}</p>
        </a>
        <Meta className={styles['author__title-date']}date={date}/>
        <p className={styles['author__title-bio']}>
          {author.loc}
        </p>
      </p>

    </div>
  );
};

export default Author;