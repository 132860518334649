// @flow strict
import React from 'react';
import { Link } from 'gatsby';
import Meta from '../Meta';
import styles from './Content.module.scss';

type Props = {
  body: string,
  title: string,
  date: date,
  text: string
};

const Content = ({ body, title, date, text }: Props) => (
  <div className={styles['content']}>
    <div className={styles['content__details']}>
      <Link className={styles['content__details-name']} to="/about/">Bryan Huang</Link>
      <div>
        <Meta className={styles['content__details-date']}date={date} />
        <span className={styles['content__details-read']}>
          {text}
        </span>
      </div>
    </div>
    <h1 className={styles['content__title']}>{title}</h1>
    <div className={styles['content__body']} dangerouslySetInnerHTML={{ __html: body }} />
  </div>
);

export default Content;
